define("discourse/plugins/discourse-group-tag-associations/discourse/templates/connectors/before-manage-group-tags/tag-associations", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">{{i18n "group_tag_associations.title"}}</label>
    <div>{{i18n "group_tag_associations.description"}}</div>
  </div>
  
  <div class="control-group">
    <TagChooser
      @tags={{model.associated_tags}}
      @allowCreate={{false}}
      @everyTag={{true}}
    />
  </div>
  */
  {
    "id": "5lbzzm+Y",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"group_tag_associations.title\"],null]],[13],[1,\"\\n  \"],[10,0],[12],[1,[28,[35,0],[\"group_tag_associations.description\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@tags\",\"@allowCreate\",\"@everyTag\"],[[30,0,[\"model\",\"associated_tags\"]],false,true]],null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-group-tag-associations/discourse/templates/connectors/before-manage-group-tags/tag-associations.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"tag-chooser\"]]",
    "moduleName": "discourse/plugins/discourse-group-tag-associations/discourse/templates/connectors/before-manage-group-tags/tag-associations.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});